import {Injectable} from "@angular/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ApiService} from "./api.service";
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Observable,
  Subscription,
} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private lastUpdated: number = 0;
  private settingsApiSubscription !: Subscription;
  private settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public settings$: Observable<any> = this.settingsSubject
    .asObservable()
    .pipe(
      takeUntilDestroyed(),
      distinctUntilChanged(),
      filter((settings: any): boolean => {
        return settings !== undefined;
      }),
    );

  public getIsBarionEnabled$ = this.settings$.pipe(
    filter((settings: any): boolean => {
      return settings !== undefined;
    }),
    map((settings) => {
      return (
        (settings.payment_barion_pg_status==='1' || settings.payment_barion_pg_status===true) &&
        (settings.payment_pg_status==='1' || settings.payment_pg_status===true)
      );
    })
  );

  private isLoadingSubject: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  public isLoading$: Observable<boolean | undefined> = this.isLoadingSubject
    .asObservable()
    .pipe(
      takeUntilDestroyed(),
      distinctUntilChanged(),
      filter((isLoading: any): boolean => {
        return isLoading !== undefined;
      }),
    );

  constructor(
    private apiService: ApiService,
  ) {
    this.refresh();
  }

  public refresh(): void {
    if (this.isLoading || ! this.isSettingsExpired()) {
      return;
    }

    this.isLoadingSubject.next(true);

    this.settingsApiSubscription = this.apiService
      .get('/app/app_settings')
      .subscribe((res) => {
        this.settingsSubject.next(res);
        this.isLoadingSubject.next(false);

        this.lastUpdated = Math.floor(Date.now() / 1000); // ms to sec

        this.settingsApiSubscription.unsubscribe();
      });
  }

  public get isLoading(): boolean | undefined {
    return this.isLoadingSubject.getValue();
  }

  public get settings() {
    return this.settingsSubject.getValue();
  }

  public getSettings(): Observable<any> {
    if ( ! this.settings || ! this.settings.length) {
      this.refresh();
    }

    return this.settings$;
  }

  public getIsBarionEnabled(): boolean {
    const settings = this.settingsSubject.getValue();

    return (
      (settings.payment_barion_pg_status === '1' || settings.payment_barion_pg_status === true) &&
      (settings.payment_pg_status === '1' || settings.payment_pg_status === true)
    );
  }

  public getIsAppleIapEnabled(): boolean {
    const settings = this.settingsSubject.getValue();

    return (
      (settings.payment_apple_iap_pg_status === '1' || settings.payment_apple_iap_pg_status === true) &&
      (settings.payment_pg_status === '1' || settings.payment_pg_status === true)
    );
  }

  public getIsPaymentEnabled(): boolean {
    const settings = this.settingsSubject.getValue();

    return settings.payment_pg_status === '1' || settings.payment_pg_status === true;
  }

  private isSettingsExpired(): boolean {
    if ( ! this.lastUpdated) {
      return true;
    }

    // last updated + 1 hr
    const validUntil = this.lastUpdated + 3600;
    const now = Math.floor(Date.now() / 1000);

    return now <= validUntil;
  }

}
